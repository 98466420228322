<template>
  <vs-card>
    <vs-table
      ref="table"
      class="text-nowrap"
      :max-items="20"
      :data="contacts"
      no-data-text="Nenhum registro para mostrar"
      pagination
      search
      stripe
    >
      <!-- Actions -->
      <div slot="header">
        <div class="d-flex align-items-center">
          <vs-button
            class="mr-2"
            icon="arrow_back"
            type="flat"
            radius
            @click="goBack"
          />

          <h3 class="draw-contacts-header__title">
            <span>{{ prizeName }}</span>

            <span class="c-title--chrevon-icon material-icons-outlined">
              chevron_right
            </span>

            <span>{{ title }}</span>
          </h3>
        </div>
      </div>

      <!-- Contact List -->
      <template slot-scope="{ data }">
        <vs-tr
          v-for="contact in data"
          :key="contact[headers.id]"
          :data="contact"
        >
          <!-- ID Number -->
          <vs-td
            v-if="headers.id"
            :data="contact[headers.id]"
          >
            <div>
              <span
                class="badge badge-light badge-pill border border-muted"
              >
                {{ contact[headers.id] }}
              </span>
            </div>
          </vs-td>

          <!-- Contact Info -->
          <vs-td :data="contact">
            <!-- Name -->
            <h5
              v-if="headers.name"
              class="mb-1"
              :style="{ fontSize: '1.25em' }"
            >
              {{ capitalizeAllWords(contact[headers.name]) }}
            </h5>

            <!-- Document -->
            <div
              v-if="
                headers.document
                  && contact[headers.document]
                  && contact[headers.document].length > 0
              "
              class="contact-info-line"
            >
              <i class="mdi mdi-card-account-details-outline mr-2" />

              <span
                class="text-muted"
                :style="{ fontSize: '1.15em' }"
              >
                {{ contact[headers.document] }}
              </span>
            </div>

            <!-- Phone -->
            <div
              v-if="
                headers.phone
                  && contact[headers.phone]
                  && contact[headers.phone].length > 0
              "
              class="contact-info-line"
            >
              <i class="mdi mdi-phone-outline mr-2" />

              <span
                class="text-muted"
                :style="{ fontSize: '1.15em' }"
              >
                {{ contact[headers.phone] }}
              </span>
            </div>

            <!-- Email -->
            <div
              v-if="
                headers.email
                  && contact[headers.email]
                  && contact[headers.email].length > 0
              "
              class="contact-info-line"
            >
              <i class="mdi mdi-email-outline mr-2" />

              <span
                class="text-muted"
                :style="{ fontSize: '1.15em' }"
              >
                {{ contact[headers.email] }}
              </span>
            </div>
          </vs-td>

          <!-- Go To Fanview -->
          <vs-td
            v-if="headers.fanview"
            :data="contact"
          >
            <div>
              <vs-button
                @click="
                  goToFanview(
                    contact.identity,
                    contact.nome,
                    contact.cel,
                    contact.email,
                    contact.cpf,
                  );
                "
              >
                Ver Detalhes
              </vs-button>
            </div>
          </vs-td>

          <!-- Delete Participant -->
          <vs-td
            v-if="headers.delete"
            :data="contact"
          >
            <div>
              <vs-button
                color="danger"
                icon="delete"
                type="border"
                @click="() => deleteParticipant(contact[headers.id])"
              >
                Excluir Participante
              </vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vs-card>
</template>

<script>
/* Helpers */
import capitalizeAllWords from '@/helpers/capitalizeAllWords';

/* Services */
import Prize from '@/services/prize';

const prizeService = new Prize();

export default {
  name: 'DrawContacts',
  props: {
    headers: {
      type: Object,
      required: true,
    },
    drawCode: {
      type: String,
      default: '',
    },
    prizeName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    contacts: [],
  }),
  computed: {
    holdList() {
      return this.$store.state.holdList;
    },
  },
  created() {
    if (this.drawCode.length > 0) {
      this.getParticipants(this.drawCode);
    } else if (this.holdList.length > 0) {
      this.contacts = this.holdList;
    }

    this.$store.commit('RESET_HOLDLIST');
  },
  methods: {
    /* API */
    async getParticipants(drawCode) {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService.getParticipants(
        drawCode,
        this.token,
      )
        .then((resp) => (this.contacts = resp))
        .catch((err) => this.handleErr(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    deleteParticipant(userSurveyId) {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService.deleteParticipant(
        userSurveyId,
        this.token,
      )
        .then(() => {
          this.deletedAlert();
          this.getParticipants(this.drawCode);
        })
        .catch((err) => this.handleErr(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Router */
    goToFanview(id, name, phone, email, document) {
      this.$store.commit('SET_HOLDLIST', this.contacts);

      this.$router.push({
        name: 'Fanview',
        params: {
          fanId: id,
          fanName: name,
          fromFilter: this.fromFilter,
          email: email || '',
          phone: phone || '',
          document: document || '',
          returnLink: '/sorteios/listar',
        },
      });
    },
    /* Helpers */
    capitalizeAllWords(sentence) {
      if (sentence) {
        return capitalizeAllWords(sentence);
      }
    },
    handleErr(err) {
      console.error(err);

      this.$vs.notify({
        title: 'Erro',
        color: 'danger',
        text: err,
      });
    },
    /* Alerts */
    deletedAlert() {
      this.$vs.notify({
        color: 'success',
        title: 'Sucesso',
        text: 'Participante excluído com sucesso!',
      });
    },
    /* Router */
    goBack() {
      this.$store.commit('RESET_HOLDLIST');
      this.$router.push('/sorteios/listar');
    },
  },
};
</script>

<style lang="scss">
.draw-contacts-header__title {
  align-items: center;
  display: flex;
  font-size: 1.3em;
  margin-left: 5px;

  .c-title--chrevon-icon {
    color: #636366;
    margin: 0 3px;
  }
}

.vs-tabs--li {
  display: block;
  position: relative;
}

.con-pagination-table.vs-table--pagination {
  justify-content: center;
  padding: 15px;
}

.con-slot-tabs {
  padding: 10px;
}

.con-vs-card {
  margin-bottom: 20px;
  border-radius: 4px;
  min-height: 200px;
}

.chipsMargin {
  margin-bottom: 5px;
}

.chipsMargin:hover {
  background-color: #cfcbcb;
  border-color: #cfcbcb;
}

.chipsRevert {
  background-color: blanchedalmond;
  color: #000;
}
</style>
